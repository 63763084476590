import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePayrollsList() {
  // Use toast
  const toast = useToast()

  const refPayrollsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'mese_di_competenza', sortable: true },
    { key: 'file', sortable: false },
  ]
  const fields = {
    id: 'id',
    mese_di_competenza: 'month',
    file: 'file_path',
  }
  const perPage = ref(10)
  const totalPayrolls = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const user_id = router.currentRoute.name === 'apps-users-view' ? router.currentRoute.params.id : null
  const yearFilter = ref(null)

  const yearOptions = [
    { label: '2020', value: 2020 },
    { label: '2021', value: 2021 },
    { label: '2022', value: 2022 },
    { label: '2023', value: 2023 },
    { label: '2024', value: 2024 },
    { label: '2025', value: 2025 },
    { label: '2026', value: 2026 },
    { label: '2027', value: 2027 },
    { label: '2028', value: 2028 },
    { label: '2029', value: 2029 },
    { label: '2030', value: 2030 },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refPayrollsListTable.value ? refPayrollsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayrolls.value,
    }
  })

  const refetchData = () => {
    refPayrollsListTable.value.refresh()
  }

  watch([currentPage, perPage, yearFilter], () => {
    refetchData()
  })

  const fetchPayrolls = (ctx, callback) => {
    store
      .dispatch('app-payrolls/fetchPayrolls', {
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        user_id,
        year: yearFilter.value
      })
      .then((response) => {
        const payrolls = response.data[0]
        const total = response.data[1]

        callback(payrolls)
        totalPayrolls.value = total
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = (date, time) => {
    let fullDate = date + ' ' + time
    return moment(fullDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
  }

  const parseDateMonth = (month) => {
    let months = {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre',
      13: 'Tredicesima',
      99: 'CUD'
    }
    return months[month]
  }

  return {
    fetchPayrolls,
    tableColumns,
    perPage,
    currentPage,
    totalPayrolls,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refPayrollsListTable,

    parseDate,
    parseDateMonth,

    refetchData,

    yearFilter,
    yearOptions
  }
}
