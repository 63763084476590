import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'utente', sortable: true },
    { key: 'email', sortable: true },
    { key: 'telefono', sortable: true },
    { key: 'ruolo', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'anagrafico_id',
    utente: 'display_name',
    email: 'email',
    telefono: 'phone_number',
    ruolo: 'role_code',
  }
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        role_code: roleFilter.value,
      })
      .then(response => {
        const users = response.data[0]
        const total = response.data[1]

        callback(users)
        totalUsers.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  const exportCsv = () => {
    store.dispatch('app-user/exportCsv', {
      text_search: searchQuery.value,
      per_page: 10000,
      page: 0,
      sort_by: fields[sortBy.value],
      sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
      role_code: roleFilter.value,
    }).then(response => {
      const now = moment().format('DD-MM-YYYY_HH-mm-ss')
      const blob = new Blob([response.data], {
        type: 'text/csv',
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${now}_utenti.csv`
      link.click()
    }).catch(() => {
      // ignore ??
    })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleName = role => {
    if (role === 'user') return 'Utente'
    if (role === 'hr') return 'HR'
    if (role === 'reception_hub') return 'Reception HUB'
    if (role === 'reception_colle') return 'Reception COLLE'
    if (role === 'accountant') return 'Contabilità'
    if (role === 'admin') return 'Admin'
    return ''
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'hr') return 'UsersIcon'
    if (role === 'reception_hub') return 'UsersIcon'
    if (role === 'reception_colle') return 'UsersIcon'
    if (role === 'accountant') return 'UsersIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserXIcon'
  }

  const resolveDepartmentUserRoleName = role => {
    if (role === 'ufficio_hr') return 'Ufficio HR'
    if (role === 'responsabile') return 'Responsabile'
    if (role === 'capo_reparto') return 'Capo Reparto'
    return 'Utente'
  }

  const parseGender = gender => {
    if (gender == 'F') return 'Femmina'
    if (gender == 'M') return 'Maschio'
    return gender
  }

  const parseDate = date => {
    const localeDate = moment(date).format('DD/MM/YYYY')
    return localeDate
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleName,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveDepartmentUserRoleName,
    parseGender,
    parseDate,
    refetchData,
    exportCsv,

    // Extra Filters
    roleFilter,
  }
}
