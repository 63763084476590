<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row class="pt-2">
          <b-col class="mb-4 d-flex justify-content-center align-items-center" cols="12">
            <feather-icon icon="FileTextIcon" size="25" class="mr-2" />
            <h1>Buste Paga</h1>
          </b-col>
        </b-row>
        <b-col cols="12" md="4" class="mb-2">
          <label>Anno</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="yearFilter"
            :options="yearOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => yearFilter = val"
          />
        </b-col>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

        </b-row>
      </div>

      <b-table
        ref="refPayrollsListTable"
        class="position-relative"
        :items="fetchPayrolls"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Busta Paga corrispondente trovata"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top">#{{ data.item.id }}</span>
          </div>
        </template>

        <template #cell(mese_di_competenza)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ parseDateMonth(data.item.month) }} {{data.item.year}}</span>
          </div>
        </template>

        <template #cell(file)="data">
          <div class="text-nowrap">
            <b-badge
              class="pr-1 pl-1"
              variant="primary"
              @click="downloadFile(data.item.id, data.item.file_path)"
            >
              <feather-icon icon="PaperclipIcon" />
                {{ data.item.file_path }}
            </b-badge>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"
              >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPayrolls"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BBadge,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import usePayrollsList from './usePayrollsList'
import payrollsStoreModule from '../payrollsStoreModule'
import axios from '@axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BBadge,
    BAvatar,

    vSelect,
  },
  setup() {
    const PAYROLLS_APP_STORE_MODULE_NAME = 'app-payrolls'

    // Register module
    if (!store.hasModule(PAYROLLS_APP_STORE_MODULE_NAME)) {
      store.registerModule(PAYROLLS_APP_STORE_MODULE_NAME, payrollsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLLS_APP_STORE_MODULE_NAME)) store.unregisterModule(PAYROLLS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPayrolls,
      tableColumns,
      perPage,
      currentPage,
      totalPayrolls,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refPayrollsListTable,
      refetchData,

      parseDate,
      parseDateMonth,

      yearFilter,
      yearOptions
    } = usePayrollsList()

    return {
      fetchPayrolls,
      tableColumns,
      perPage,
      currentPage,
      totalPayrolls,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refPayrollsListTable,
      refetchData,

      parseDate,
      parseDateMonth,

      yearFilter,
      yearOptions
    }
  },
  methods: {
    downloadFile(id, file) {
      const url = `/paychecks/download/${id}`
      const file_name = file
      axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: response.type })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file_name
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.col-1 svg:hover {
  cursor: pointer;
}
.badge:hover {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
