<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Errore dati Utente
      </h4>
      <div class="alert-body">
        Nessun Utente trovato con questo ID. Controlla la
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list' }"
        >
          Lista degli Utenti
        </b-link>
        per altri.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <users-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col
          class="mb-2"
          cols="12"
        >
          <events-list />
        </b-col>
        <b-col cols="12">
          <payrolls-list />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import EventsList from '@/views/apps/events/events-list/EventsList.vue'
import PayrollsList from '@/views/apps/payrolls/payrolls-list/PayrollsList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import UsersViewUserInfoCard from './UsersViewUserInfoCard.vue'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    EventsList,
    PayrollsList,
    UsersViewUserInfoCard,
  },
  setup() {
    const toast = useToast()
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style></style>
