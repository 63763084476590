<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="UserIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Utente</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-5 text-center"
        cols="12"
      >
        <b-avatar
          size="250"
          :src="userData.avatar_file ? mediaUrl + userData.avatar_file : ''"
          :text="avatarText(userData.display_name)"
          :variant="`light-${resolveUserRoleVariant(userData.role_code)}`"
        />
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          ID Utente:
        </p>
        <h3 class="mb-1">
          #{{ userData.anagrafico_id || userData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Nome Utente:
        </p>
        <h3 class="mb-1">
          {{ userData.display_name }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Identificativo:
        </p>
        <h3 class="mb-1">
          @{{ userData.identifier }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.gender"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Sesso:
        </p>
        <h3 class="mb-1">
          {{ parseGender(userData.gender) }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Ruolo:
          <feather-icon
            v-if="userData.is_approvator == 1"
            :id="'role-tooltip-' + userData.id"
            icon="AwardIcon"
            size="18"
            class="ml-1 text-success"
          />
          <b-tooltip
            v-if="userData.is_approvator == 1"
            :target="'role-tooltip-' + userData.id"
            triggers="hover"
            placement="bottom"
          >
            <div>
              Utente Approvatore
            </div>
          </b-tooltip>
        </p>
        <b-form-select
          v-model="userData.role_code"
          :options="roleOptions"
          @change="editUserRole"
        />
      </b-col>
      <b-col
        v-if="userData.phone_sms"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Cellulare:
        </p>
        <h3 class="mb-1">
          {{ userData.phone_sms }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.phone_number_office"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Telefono Ufficio:
        </p>
        <h3 class="mb-1">
          {{ userData.phone_number_office }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.email"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Email:
        </p>
        <h4 class="mb-1">
          {{ userData.email }}
        </h4>
      </b-col>
      <b-col
        v-if="userData.email_office"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Email Ufficio:
        </p>
        <h4 class="mb-1">
          {{ userData.email_office }}
        </h4>
      </b-col>
      <b-col
        v-if="userData.fiscal_code"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Codice Fiscale:
        </p>
        <h3 class="mb-1">
          {{ userData.fiscal_code }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.birthday"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Data di Nascita:
        </p>
        <h3 class="mb-1">
          {{ parseDate(userData.birthday) }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.assumption_day"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Data di Assunzione:
        </p>
        <h3 class="mb-1">
          {{ parseDate(userData.assumption_day) }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.matricola"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Matricola:
        </p>
        <h3 class="mb-1">
          {{ userData.matricola }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.badge"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Badge:
        </p>
        <h3 class="mb-1">
          {{ userData.badge }}
        </h3>
      </b-col>
      <b-col
        v-if="userData.headquarter"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Sede di Appartenenza:
        </p>
        <h3 class="mb-1">
          <feather-icon
            icon="MapPinIcon"
            size="25"
            class="text-primary"
          />
          <b-link
            :to="{ name: 'apps-headquarters-view', params: { id: userData.headquarter.id } }"
            class="font-weight-bold text-nowrap"
          >
            #{{ userData.headquarter.id }} - {{ userData.headquarter.name }}
          </b-link>
        </h3>
      </b-col>
      <b-col
        v-if="userData.department"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Reparto di Appartenenza:
        </p>
        <h3 class="mb-1">
          <feather-icon
            icon="UsersIcon"
            size="25"
            class="text-primary"
          />
          <b-link
            :to="{ name: 'apps-departments-view', params: { id: userData.department.id } }"
            class="font-weight-bold text-nowrap"
          >
            #{{ userData.department.id }} - {{ userData.department.name }}
          </b-link>
        </h3>
      </b-col>
      <!-- <b-col
        v-if="userData.department_role_code"
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Ruolo nel Reparto:
        </p>
        <h3 class="mb-1">
          {{ resolveDepartmentUserRoleName(userData.department_role_code) }}
        </h3>
      </b-col> -->
      <b-col
        :key="worktimesKey"
        cols="12"
        class="text-center mt-2"
      >
        <b-button
          v-if="!userData.enable_work_time_logs"
          variant="primary"
          class="text-nowrap"
          @click="toggleWorktimes()"
        >Abilita Timbrature</b-button>
        <b-button
          v-if="userData.enable_work_time_logs"
          variant="primary"
          class="text-nowrap"
          @click="toggleWorktimes()"
        >Disabilita Timbrature</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink, BFormSelect, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BFormSelect,
    BTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const roleOptions = [
      { text: 'Seleziona Ruolo', value: null },
      { text: 'Admin', value: 'admin' },
      { text: 'HR', value: 'hr' },
      { text: 'Reception HUB', value: 'reception_hub' },
      { text: 'Reception COLLE', value: 'reception_colle' },
      { text: 'Contabilità', value: 'accountant' },
      { text: 'Utente', value: 'user' },
    ]
    const mediaUrl = process.env.VUE_APP_MEDIA_URL
    const {
      resolveUserRoleVariant, resolveUserRoleIcon, resolveDepartmentUserRoleName, parseGender, parseDate,
    } = useUsersList()
    return {
      roleOptions,
      mediaUrl,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDepartmentUserRoleName,
      parseGender,
      parseDate,
      worktimesKey: 0,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    editUserRole() {
      const { id } = router.currentRoute.params
      const formData = {
        role_code: this.userData.role_code,
      }
      store
        .dispatch('app-user/updateUser', { id, formData })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ruolo modificato con successo',
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    toggleWorktimes() {
      const { id } = router.currentRoute.params
      const workTimeLogs = this.userData.enable_work_time_logs === 1 ? 0 : 1
      const formData = {
        enable_work_time_logs: workTimeLogs,
      }
      store
        .dispatch('app-user/updateUser', { id, formData })
        .then(() => {
          this.userData.enable_work_time_logs = workTimeLogs
          this.worktimesKey++
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Gestione Timbrature modificata con successo',
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
